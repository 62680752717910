<template>
  <d-remote-monitoring-indicators @on-select-indicator="onSelectIndicatorHandler" />
</template>

<script>

export default {
  name: 'RemoteMonitoringInidicators',
  page: {
    title: 'CRM Doctis - Индикаторы ДМ',
  },
  methods: {
    onSelectIndicatorHandler(id) {
      this.$router.push(`/remote-monitoring/indicators/view/${id}/`);
    },
  },
};
</script>
